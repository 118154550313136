import config from './animation-config';

let brakeFactor = 1; // to brake animation when we are hovering over a “hotspot” 0 == stop, 1 = no brake
let braking = false;
let brakingForProject = false;
let resetProgress = 0;
let xAccel = 0;							// horizontal acceleration factor will range between -0.5 - 0.5 depending on user interaction
const resetDuration = 15;
let resetComplete = false;

// perform a tangential sprite animation e.g. y movement or rotation.
function animateSprite(groupProgress, sprite, ani) {
	// groupProgress is going to be between -0.5 and 0.5 and start at 0
	// start and endpoints
	// this part of things is kinda wonky
	if (groupProgress < ani.start || groupProgress > ani.end) return;
	if (ani.start !== -0.5 || ani.end !== 0.5) {
		groupProgress = 0.5 - ((groupProgress - ani.start) / (ani.end - ani.start));
	}

	let simpleProgress = (groupProgress + 0.5) * ani.count;
	if (ani.type === 'rotate') {
		simpleProgress += 0.5;
	}
	let simpleProgressMultiplied = 1 - (simpleProgress % 1);
	const phase = Math.floor(simpleProgress) % 2;
	if (phase === 0) {
		simpleProgressMultiplied = 1 - simpleProgressMultiplied;
	}
	if (ani.type === 'rotate') {
		const angle = (simpleProgressMultiplied - 0.5) * (ani.max - ani.min);
		sprite.r = angle;
		sprite.el.style.transformOrigin = ani.origin;
		sprite.el.style.transform = `rotate(${angle}deg)`;
	} else {
		const value = ani.min + (simpleProgressMultiplied * (ani.max - ani.min));
		if (ani.type === 'y') {
			sprite.offY = value;
			sprite.el.style.top = (sprite.y + value) + '%';
		} else if (ani.type === 'x') {
			sprite.offX = value;
			sprite.el.style.left = (sprite.x + value) + '%';
		}
	}
}

const animation = {

	// groups of sprites
	groups: [],
	// actual OHU project representations
	projects: [],
	collageEl: null,
	offStage: 0,
	usingTouch: false,

	/**
	 * Configuration/initialization of the animation state and parameters
	 * Add references to DOM elements
	 * @param component – controlling WhiteLabel component
	 */
	prepare: function (component) {
		const collageEl = this.collageEl = component.element.querySelector(component.dataSelector('main'));
		const spriteEls = collageEl.querySelectorAll(component.dataSelector('sprite'));
		for (const spriteEl of spriteEls) {
			const groupName = component.dataAttr(spriteEl).get('group');
			let group = this.groups.find(g => g.name === groupName);
			if (!group) {
				group = {
					name: groupName,
					sprites: [],
					x: 0, // current x offset of the group (%)
				};
				const groupConfig = config.getGroup(groupName);
				if (groupConfig) {
					group = Object.assign(group, groupConfig);
				} else {
					console.warn(`Missing configuration for collage group *${groupName}*`);
				}
				this.groups.push(group);
			}
			const spriteName = component.dataAttr(spriteEl).get('name');
			let sprite = {
				el: spriteEl,
				name: spriteName,
				group: group,
				x: parseFloat(spriteEl.style.left), // base x pos of sprite (%)
				y: parseFloat(spriteEl.style.top),	// base y pos of sprite (%)
			};
			const spriteConfig = config.getSprite(spriteName);
			if (spriteConfig) {
				sprite = Object.assign(sprite, spriteConfig);
			} else {
				console.warn(`Missing configuration for collage sprite *${spriteName}*`);
			}
			group.sprites.push(sprite);
		}

		const projectEls = collageEl.querySelectorAll(component.dataSelector('project'));
		const labelEls = collageEl.querySelectorAll(component.dataSelector('label'));
		for (let i = 0; i < projectEls.length; i++) {
			const projectEl = projectEls[i];
			const labelEl = labelEls[i];
			const teaserEl = projectEl.querySelector(component.dataSelector('teaser'));
			const closerEl = projectEl.querySelector(component.dataSelector('close'));
			const project = {
				el: projectEl,
				group: this.groups.find(g => g.name === component.dataAttr(projectEl).get('group')),
				labelEl: labelEl,
				teaserEl: teaserEl,
				closerEl: closerEl,
				idx: component.dataAttr(projectEl).get('idx'),
				x: parseFloat(projectEl.style.left),
				y: parseFloat(projectEl.style.top),
				labelX: parseFloat(labelEl.style.left)
			};
			this.projects.push(project);
		}

	},

	reinitialize: function () {
		xAccel = 0;
		braking = false;
		brakeFactor = 1;
		for (const group of this.groups) {
			group.x = 0;
		}
	},

	// main animation loop function
	update: function () {
		// if we are hovering over a project we need to brake
		// we only do this if the mouse is central to the window
		// we slow using a brakeFactor. 0 == stop, 1 = no brake
		const centralHover = Math.abs(xAccel) < 0.15;
		if (braking && (!brakingForProject || centralHover)) {
			if (brakingForProject) {
				brakeFactor -= 0.02;
			} else {
				brakeFactor -= 0.015; // brake softer and longer on mobile
			}
			brakeFactor = Math.max(0, brakeFactor);
		}
		if (!braking) {
			brakeFactor += 0.08; // speed up faster than we break
			brakeFactor = Math.min(1, brakeFactor);
		}

		// animate groups
		let group;
		let speedInc;
		let easeFactor;
		let overallDistance;
		for (group of this.groups) {

			// horizontal speed of each group will also depend on its “z” level – basically corresponding to layer order in Photoshop
			const depthFactor = 0.75 + (group.z / config.maxZ); // 1 + Math.sin((z / numGroups) * (Math.PI / 2));

			speedInc = brakeFactor * depthFactor * xAccel;

			// ease the speed to prevent abrupt halting
			overallDistance = group.maxLeft - group.minLeft;
			easeFactor = Math.abs(Math.cos((group.x / overallDistance) * Math.PI));
			easeFactor = Math.max(0.1, easeFactor);
			if (!this.usingTouch) {
				speedInc *= easeFactor;
			}

			// set the group position
			group.x += speedInc;
			group.x = Math.max(group.minLeft, group.x);
			group.x = Math.min(group.maxLeft, group.x);
			group.progress = group.x / overallDistance;
			for (const sprite of group.sprites) {
				sprite.el.style.left = (sprite.x + group.x) + '%';
				if (group.animations) {
					for (const ani of group.animations) {
						animateSprite(group.progress, sprite, ani);
					}
				}
				if (sprite.animations) {
					for (const ani of sprite.animations) {
						animateSprite(group.progress, sprite, ani);
					}
				}
			}
		}
		// move project teasers
		for (const project of this.projects) {
			project.el.style.left = (project.group.x + project.x) + '%';
			project.labelEl.style.left = (project.group.x + project.labelX) + '%';
		}

		// move entire stage for smaller screens (otherwise we lose access to sprites at the sides)
		if (this.offStage > 20) {
				this.stageX = group.progress * this.offStage;
				this.collageEl.style.marginLeft = this.stageX + '%';
		}

	},

	prepareReset: function () {
		resetComplete = false;
		resetProgress = 0;
	},

	resetUpdate: function () {
		const progress = 1 - (resetProgress / resetDuration);
		let group;
		for (group of this.groups) {
			const x = group.x * progress;
			for (const sprite of group.sprites) {
				sprite.el.style.left = (sprite.x + x) + '%';
				if (typeof (sprite.offY) !== 'undefined') {
					sprite.el.style.top = (sprite.y + (progress * sprite.offY)) + '%';
				}
				if (typeof (sprite.r) !== 'undefined') {
					sprite.el.style.transform = `rotate(${progress * sprite.r}deg)`;
				}
			}
		}
		if (this.stageX) {
			this.collageEl.style.marginLeft = (this.stageX * progress) + '%';
		}
		resetProgress++;
		if (resetProgress === resetDuration) {
			this.collageEl.style.marginLeft = '0%';
			resetComplete = true;
		}
	},

	isReset: function () {
		return resetComplete;
	},

	brake: function(brake = true, forProject = false) {
		brakingForProject = forProject;
		braking = brake;
	},

	setXAcceleration: function (v) {
		xAccel = v;
	}


};

export default animation;
