import commonConfig from './common';

import animationConfig from '../animation/_config';
import appConfig from '../app/_config';
import contextConfig from '../context/_config';
import pageConfig from '../page/_config';
import masonryGridConfig from '../masonry-grid/_config';
import sharePopupConfig from '../share-popup/_config';
import selectConfig from '../select/_config';
import toolboxConfig from '../toolbox/_config';
import mediaContentConfig from '../media-content/_config';
import mediaTogglerConfig from '../media-toggler/_config';
import mediaModalConfig from '../media-modal/_config';
import collageConfig from '../collage/_config';
import trackersConfig from '../trackers/_config';
import twitterConfig from '../twitter/_config';

const config = (di) => {
	commonConfig(di);
	animationConfig(di);
	appConfig(di);
	contextConfig(di);
	pageConfig(di);
	masonryGridConfig(di);
	sharePopupConfig(di);
	mediaContentConfig(di);
	mediaTogglerConfig(di);
	mediaModalConfig(di);
	toolboxConfig(di);
	selectConfig(di);
	collageConfig(di);
	trackersConfig(di);
	twitterConfig(di);

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');
	di
		.setValue('baseUrl', baseUrl)
		.setValue('google/mapApiUrl', bodyData.get('googleMapApiUrl', ''))
		.setValue('google/mapApiKey', bodyData.get('googleMapApiKey', ''))
		.setParam('PageLoader', 'baseUrl', baseUrl)
		.setParam('HashScroller', 'duration', 0)
		.setParam('Navigation', 'navigateSameUrl', true)
	;
};


export default config;
