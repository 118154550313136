import PageComponent from '../../common/component/page-component';

class MediaContent extends PageComponent {

	constructor({
								root,
								element,
							}) {
		super({root: root, element: element});
		this.media = null;
		this.type = null;
		this.homeElement = null;
	}

	prepare() {
		const selector = this.dataSelector('id', this.dataAttr().get('mediaId'));
		this.type = this.dataAttr().get('type');
		this.media = this.components.queryComponent(this.root, selector);
		this.homeElement = this.element.parentNode;
	}

}

export default MediaContent;
