import PageComponent from '../../common/component/page-component';

// The OpenHU “toolbox” – row of buttons in the sidebar
class Toolbox extends PageComponent {

	prepare() {
		this.buttons = [];
		this.activeButton = null;
		this.activeClass = this.classListParser.buildString('active');
		// make a list of button objects and work out which button, if any, is currently active
		const buttonEls = this.element.querySelectorAll(this.dataSelector('toolboxAction'));
		for (const el of buttonEls) {
			const data = this.dataAttr(el);
			const button = {
				action: data.get('toolboxAction'),
				tips: data.get('tips'),
				el: el,
				tipEl: el.querySelector('.' + this.classListParser.buildString('tip'))
			};
			if (el.classList.contains(this.activeClass)) {
				this.activeButton = button;
			}
			this.listeners.click = this.events.on(el, 'click', this.onAction.bind(this, button.action));
			this.buttons.push(button);
		}
		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
		this.listeners.onShowMedia = this.events.on(this.root, 'media:show', this.onMediaShow.bind(this));
	}

	onMediaShow(ev) {
		const data = ev.detail;
		const {content} = data;
		if (content.type === 'slideshow') {
			this.setActiveButton('gallery');
		} else if (content.type === 'video') {
			this.setActiveButton('video');
		}
	}

	onAction(action, ev) {
		const currentContext = this.contexts.getCurrentContext().getName();
		if (currentContext === 'media') {
			// if we’re in the media modal
			if (action === 'close' || action === 'text') {
				// close it
				this.contexts.pop();
				if (ev) {
					ev.preventDefault();
				}
			}
		}
	}

	setActiveButton(action) {
		if (this.activeButton !== null) {
			this.activeButton.el.classList.remove(this.activeClass);
		}
		const button = this.getButton(action);
		if (button) {
			this.activeButton = button;
			this.activeButton.el.classList.add(this.activeClass);
		}
	}

	getButton(name) {
		return this.buttons.find(b => b.action === name);
	}

	onContextSwitch(event) {
		const currentContext = this.contexts.getCurrentContext().getName();
		// update tooltips depending on context
		for (const button of this.buttons) {
			if (button.tips && button.tips[currentContext] && button.tipEl) {
				button.tipEl.innerHTML = button.tips[currentContext];
			}
		}
		// set active button to text if we are not in the media modal
		const prevContext = event.detail.previousContext.getName();
		if (currentContext === 'default' && prevContext === 'media') {
			this.setActiveButton('text');
		}
	}


}

export default Toolbox;
