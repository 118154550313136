import DefaultPage from './default-page';

class HomePage extends DefaultPage {
	constructor({root, element, url = '', title = ''}) {
		super({root: root, element: element, url: url, title: title});
	}

	init() {
		console.log('PAGE INIT');
	}
}

export default HomePage;
