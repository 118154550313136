import copy from 'copy-to-clipboard';
import PageComponent from '../../common/component/page-component';

class SharePopup extends PageComponent {

	prepare() {
		this.isOpen = false;
		this.toggleClass = 'show';
		this.closer = this.element.querySelector('.' + this.classListParser.buildString('close'));
		this.listeners.close = this.events.on(this.closer, 'click', this.hide.bind(this));
	}

	toggle() {
		if (this.isOpen) {
			this.hide();
		} else {
			this.show();
		}
	}

	hide() {
		this.classList(this.element).remove(this.toggleClass);
		this.listeners.escape.destroy();
		this.listeners.clickOutside.destroy();
		this.isOpen = false;
	}

	show() {
		this.classList(this.element).add(this.toggleClass);
		copy(window.location.href);
		this.element.focus();
		this.listeners.escape = this.events.on(this.element, 'keydown', this.escape.bind(this));
		// need to delay, otherwise clickOutside is called immediately as the event bubbles up
		setTimeout(() => {this.listeners.clickOutside = this.events.on(document, 'body', 'click', this.clickOutside.bind(this));}, 100);
		this.isOpen = true;
	}

	escape(ev) {
		if (ev.keyCode === 27) {
			this.hide();
		}
	}

	clickOutside(ev) {
		if (!this.element.contains(ev.target)) {
			this.hide();
		}
	}

}

export default SharePopup;
