import ModalContext from './modal-context';


class MenuContext extends ModalContext {



	switchIn(otherContext) {
		const element = this.getElement();
		element.setAttribute('aria-hidden', false);
		this.linkListener = this.events.on(this.getElement(), 'a', 'click', this.onLinkClick.bind(this), {capture: true});
		return this.threeStateTransition(element).add(this.activeClass);
	}

	switchOut(otherContext) {
		const element = this.getElement();
		element.setAttribute('aria-hidden', true);
		this.linkListener.destroy();
		return this.threeStateTransition(element).remove(this.activeClass).then(() => {
			this.classList(element).remove(this.fixedClass);
			return otherContext;
		});
	}

	onLinkClick(event, target) {
		if (this.contexts.getCurrentContext() === this) {
				this.contexts.pop();
		}
	}

}


export default MenuContext;
