import MatomoTracker from './matomo-tracker';
import MatomoCustomEventTracker from './matomo-custom-event-tracker';


export default (di) => {
	di
		.setType({type: MatomoTracker, name: 'MatomoTracker', parent: 'Tracker', params: {name: 'matomo'}})
		.setType({type: MatomoCustomEventTracker, name: 'MatomoCustomEventTracker', mixins: ['domMixin'], setters: {
			injectTracker: di.lazyGet('tracker/matomoTracker')
		}})
		.set('tracker/matomoTracker', di.lazyNew('MatomoTracker'))
		.set('tracker/matomoCustomEventTracker', di.lazyNew('MatomoCustomEventTracker'))
	;
};
