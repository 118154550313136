import BookingContext from './booking-context';
import DefaultContext from './default-context';
import MenuContext from './menu-context';
import ModalContext from './modal-context';
import TrackersContext from './trackers-context';
import MediaContext from './media-context';
//import CollageContext from './collage-context';

export default (di) => {
	di
		.setType({type: BookingContext, name: 'BookingContext', parent: 'ModalContext'})
		.setType({type: DefaultContext, name: 'DefaultContext', parent: 'Context', params: {scrollMode: 'none'}})
		.setType({type: MenuContext, name: 'MenuContext', parent: 'ModalContext'})
		.setType({type: ModalContext, name: 'ModalContext', parent: 'Context'})
		.setType({type: TrackersContext, name: 'TrackersContext', parent: 'Context'})
		.setType({type: MediaContext, name: 'MediaContext', parent: 'Context'})
		//.setType({type: CollageContext, name: 'CollageContext', parent: 'Context'})
	;
};
