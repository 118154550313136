import PageComponent from '../../common/component/page-component';
//import Twitter from 'twitter-v2';

class TwitterModule extends PageComponent {

	constructor({
								root,
								element,
							}) {
		super({root: root, element: element});
	}

	prepare() {
		console.log('t: ' + window.twttr);
		if(window.twttr && window.twttr.widgets) {
			window.twttr.widgets.load();
		}
	}
}

export default TwitterModule;
