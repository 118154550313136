const shoogle = (at) => ({
	type: 'rotate',
	min: 3,
	max: -3,
	count: 6,
	start: at,
	end: at + 0.1
});

const animationConfig = {
	groups: [
		{
			name: 'dancer',
		}, {
			name: 'elderly-couple-group',
		}, {
			name: 'palm-group'
		}, {
			name: 'woman-in-red',
		}, {
			name: 'u-bahn-unter-den-linden'
		}, {
			name: 'amoebe-schloss'
		}, {
			name: 'statue-plane',
			animations: [{
				type: 'y',
				min: -20,
				max: 0,
				count: 2,
			}]
		}, {
			name: 'mikroskop'
		}, {
			name: 'main-buildings'
		}, {
			name: 'dino'
		}, {
			name: 'male-scientist'
		}, {
			name: 'interieur',
		}, {
			name: 'kuppel',
		}, {
			name: 'alexander-humboldt',
		}, {
			name: 'hand',
		}, {
			name: 'campus-nord',
			z: 10,
		}, {
			name: 'wilhelm-humboldt',
			z: 2,
		}
	],
	sprites: [{
		name: 'dancer',
	}, {
		name: 'elderly-couple',
	}, {
		name: 'solarized-tree'
	}, {
		name: 'solarized-palm'
	}, {
		name: 'greenery-inverted',
		animations: [shoogle(0.4)]
	}, {
		name: 'greenery-inverted-1',
		animations: [shoogle(0.4)]
	}, {
		name: 'palme',
	}, {
		name: 'woman-in-red',
	}, {
		name: 'greenery-inverted-2',
		animations: [shoogle(0.2)]
	}, {
		name: 'spitze',
	}, {
		name: 'u-bahn-unter-den-linden',
		animations: [{
			type: 'rotate',
			min: 0,
			max: 15,
			count: 2,
			start: -0.5,
			end: -0.1,
			origin: 'bottom center'
		}]
	}, {
		name: 'schloss',
	}, {
		name: 'amoebe',
	}, {
		name: 'greenery',
		animations: [shoogle(0.1)]
	}, {
		name: 'greenery-1'
	}, {
		name: 'plane',
	}, {
		name: 'statur',
	}, {
		name: 'mikroskop',
		animations: [{
			type: 'rotate',
			min: 20,
			max: 0,
			count: 1,
			start: 0,
			end: 0.5
		}]
	}, {
		name: 'haupt-gebaeude',
	}, {
		name: 'boys',
	}, {
		name: 'tier-anatomisches-theater',
	}, {
		name: 'dino',
		animations: [{
			type: 'y',
			min: -15,
			max: 0,
			count: 2
		}, {
			type: 'rotate',
			min: 15,
			max: -35,
			count: 2,
			origin: 'center bottom'
		}]
	}, {
		name: 'greenery-2',
		animations: [shoogle(-0.3)]
	}, {
		name: 'male-scientist',
	}, {
		name: 'interieur',
	}, {
		name: 'kuppel',
	}, {
		name: 'alexander-humboldt',
		animations: [{
			type: 'rotate',
			min: 15,
			max: 0,
			count: 2,
			origin: 'bottom right'
		}]
	}, {
		name: 'hand',
		animations: [{
			type: 'rotate',
			min: -2,
			max: 2,
			count: 10,
			start: -0.3,
			end: -0.05,
			origin: 'top center'
		}, {
			type: 'y',
			min: -1,
			max: 1,
			count: 10,
			start: -0.3,
			end: -0.05
		}]
	}, {
		name: 'campus-building',
	}, {
		name: 'wilhelm-humboldt',
		animations: [{
			type: 'rotate',
			min: 15,
			max: 0,
			count: 2,
			origin: 'bottom left'
		}]
	}],
	getGroup: function(name) {
		return this.groups.find(g => g.name === name);
	},
	getSprite: function(name) {
		return this.sprites.find(s => s.name === name);
	}
};

const defaultAnimationConfig = {
	start: -0.5,
	dir: 1,
	end: 0.5,
	value: 0,
	count: 1,
	origin: 'center'
};

function getAnimationConfig(animation) {
	if (animation.type === 'rotate') {
		animation = Object.assign({}, defaultAnimationConfig, {
			min: -180,
			max: 180,
		}, animation);
	} else {
		animation = Object.assign({}, defaultAnimationConfig, animation);
	}
	return animation;
}

function initAnimationConfig(obj) {
	if (obj.animations) {
		for (let i = 0; i < obj.animations.length; i++) {
			obj.animations[i] = getAnimationConfig(obj.animations[i]);
		}
	}
}

for (let i = 0; i < animationConfig.groups.length; i++) {
	animationConfig.groups[i] = Object.assign({
		z: i + 1, 		// depth factor to determine horizontal speed (parallax)
		minLeft: -30,	// minimal x offset to the left as a percentage
		maxLeft: 30,	// maximal x offset to the right as a percentage
	}, animationConfig.groups[i]);
	initAnimationConfig(animationConfig.groups[i]);
}

for (let i = 0; i < animationConfig.sprites.length; i++) {
	initAnimationConfig(animationConfig.sprites[i]);
}

if (!animationConfig.maxZ) {
	animationConfig.maxZ = animationConfig.groups.reduce((acc, group) => Math.max(acc, group.z), 0);
}

export default animationConfig;
